import { ReactNode, useState } from 'react';
import Modal from 'react-modal';
import SimpleModal from '../core/SimpleModal';

interface PortalModalProps extends Omit<Modal.Props, 'isOpen'> {
    isOpen?: boolean;
    title?: React.ReactNode;
    subtitle?: string;
    opener?: (onOpen: () => void) => JSX.Element;
    openerText?: ReactNode;
    onCloseAction?: () => void | Promise<void>;
    innerCloseButton?: boolean;
    fullscreen?: boolean;
    children?: any;
    className?: string;
}

const PortalModal = ({ isOpen = false, opener, openerText, onCloseAction, children, ...others }: PortalModalProps) => {
    const [isModalOpen, setModalOpen] = useState(isOpen);

    const onClose = async () => {
        if (onCloseAction) {
            try {
                await onCloseAction();
                setModalOpen(false);
            } catch (e) {
                console.error(e);
            }
        } else {
            setModalOpen(false);
        }
    };

    const onOpen = () => setModalOpen(true);

    return (
        <>
            {opener ? (
                opener(onOpen)
            ) : openerText ? (
                <button className="btn btn--lg" onClick={onOpen}>
                    {openerText}
                </button>
            ) : null}
            <SimpleModal isOpen={isModalOpen} onClose={onClose} {...others}>
                {children}
            </SimpleModal>
        </>
    );
};

export default PortalModal;
