import cn from 'classnames';
import { ReactNode } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#__next');

const MODAL_DEFAULT_STYLES = {
    overlay: {
        zIndex: 2147483010,
    },
};

type SimpleModalProps = Omit<Modal.Props, 'children'> & {
    title?: React.ReactNode;
    subtitle?: string;
    onClose?: () => void;
    innerCloseButton?: boolean;
    fullscreen?: boolean;
    className?: string;
    style?: Modal.Props['style'];
    children: ((props: { isOpen: boolean; onClose: () => void }) => ReactNode) | ReactNode;
};

export default function SimpleModal({
    isOpen,
    onClose = () => {},
    style,
    fullscreen,
    innerCloseButton,
    title,
    subtitle,
    className,
    children,
    ...others
}: SimpleModalProps) {
    return (
        <Modal
            isOpen={isOpen}
            className="popup-content"
            style={{
                content: style?.content,
                overlay: { ...MODAL_DEFAULT_STYLES.overlay, ...style?.overlay },
            }}
            onRequestClose={onClose}
            shouldCloseOnEsc
            shouldCloseOnOverlayClick
            overlayClassName={cn('popup-wrapper', { 'popup-wrapper--fullscreen': fullscreen })}
            {...others}
        >
            {!innerCloseButton && <CloseButton onClick={onClose} />}

            {!!title && (
                <div className="popup-content__head">
                    <h2 className="popup-content__title">{title}</h2>
                    {!!subtitle && <p className="popup-content__text">{subtitle}</p>}
                </div>
            )}

            <div className={cn('popup', className)}>
                {innerCloseButton && <CloseButton onClick={onClose} />}
                {typeof children === 'function' ? children({ isOpen, onClose }) : children}
            </div>
        </Modal>
    );
}

const CloseButton = ({ onClick }) => (
    <button className="popup-content__close" onClick={onClick}>
        <svg
            className="icon icon-close"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </button>
);
