export const CHANNEL_NAME = 'portal_auth_channel';
export enum MessageType {
    SUCCESS,
    FAILURE,
}

export const TIMEOUT = 5 * 60 * 1000; // 5 min

export const useAuthPopup = ({ authUrl }) => {
    const authenticate = () => {
        return new Promise<void>((resolve, reject) => {
            const authWindow = window.open(authUrl, 'Authorization', 'popup,width=450,height=720');

            if (authWindow) {
                let isDone = false;
                const channel = new BroadcastChannel(CHANNEL_NAME);

                // doesn't always work (`closed` returns `true` even if the window is still open)
                // so use bigger timeout
                const interval = setInterval(() => {
                    if (authWindow.closed) {
                        channel.close();
                        clearInterval(interval);

                        if (!isDone) {
                            isDone = true;
                            reject(new AuthorizationAbortError());
                        }
                    }
                }, TIMEOUT);

                channel.onmessage = (event) => {
                    isDone = true;

                    switch (event.data?.type) {
                        case MessageType.SUCCESS:
                            resolve();
                            break;
                        case MessageType.FAILURE:
                            reject(new AuthorizationFailedError(event.data?.message));
                            break;
                        default:
                            reject(new UnknownMessageTypeError());
                    }

                    channel.postMessage({
                        type: MessageType.SUCCESS,
                    });
                };
            } else {
                reject(new AuthorizationInitializationError());
            }
        });
    };

    return { authenticate };
};

export class AuthorizationAbortError extends Error {
    name = 'AuthorizationAbortError';

    constructor(message: string = 'Authorization aborted') {
        super(message);
    }
}

export class AuthorizationFailedError extends Error {
    name = 'AuthorizationFailedError';

    constructor(message: string = 'Authorization failed') {
        super(message);
    }
}

export class AuthorizationInitializationError extends Error {
    name = 'AuthorizationInitializationError';

    constructor(message: string = 'Failed to open authorization popup') {
        super(message);
    }
}

export class UnknownMessageTypeError extends Error {
    name = 'UnknownMessageTypeError';

    constructor(message: string = 'Unknown message type') {
        super(message);
    }
}
