import React, { useContext } from 'react';
import Link from 'next/link';
import { getAvatar, getName, getProfileUrl, getUserCountryAndCompany } from '../../../users/utils';
import { TagComponent } from '../tags-list/TagComponent';
import { ContentListItemProps } from './ContentList';
import { AccessLink, CardHeader, CardDescription, VideoProgress } from './common';
import { AccessType, ContentCategory } from '@gn/core/type';
import { event } from 'app/analytics';
import { CurrentUserContext } from '../CurrentUserContext';
import { EditButton } from './EditButton';
import { getEditButtonURL } from './utils';
import { Mentorship, MentorshipStatus, User } from '@gn/db/client';
import FireIcon from '../icons/FireIcon';
import { getUserNameWithFallback } from '@gn/core/user/utils';

interface AuthorListItemProps {
    user: Pick<User, 'id' | 'name' | 'shortBio' | 'avatar' | 'nickname' | 'company'> & {
        mentorship: null | Pick<Mentorship, 'status'>;
    };
    isImgLazyLoading?: boolean;
    isCountryAndCompany?: boolean;
}

export function AuthorsListItem(props: AuthorListItemProps) {
    const { user, isImgLazyLoading, isCountryAndCompany } = props;

    return (
        <Link href={getProfileUrl(user)} prefetch={false} className="ab-author">
            <div className="ab-author__img">
                <img
                    src={getAvatar(user)}
                    alt={getUserNameWithFallback(user)}
                    loading={isImgLazyLoading ? 'lazy' : undefined}
                    // @ts-ignore
                    fetchpriority={isImgLazyLoading ? undefined : 'high'}
                />
            </div>
            <div className="ab-author__content">
                <div className="ab-author__name">{getName(user)}</div>
                {!!(user.shortBio || user.company) && isCountryAndCompany && (
                    <div className="ab-author__company">{user.shortBio || user.company}</div>
                )}
                {user.mentorship?.status === MentorshipStatus.Enabled && (
                    <div className="ab-author__mentorship label label--mentorship">Mentorship available</div>
                )}
            </div>
        </Link>
    );
}

export function AuthorsShortListItem(props: AuthorListItemProps) {
    const { user, isImgLazyLoading } = props;
    return (
        <Link href={getProfileUrl(user)} prefetch={false} className="ab-author">
            <div className="ab-author__img">
                <img
                    src={getAvatar(user)}
                    alt={getUserNameWithFallback(user)}
                    loading={isImgLazyLoading ? 'lazy' : undefined}
                    // @ts-ignore
                    fetchpriority={isImgLazyLoading ? undefined : 'high'}
                />
            </div>
        </Link>
    );
}

function AuthorsList({ users, isImgLazyLoading }) {
    let content;
    if (users && users.length > 1) {
        content = (
            <>
                {users.map((user) => {
                    return <AuthorsShortListItem key={user.id} user={user} isImgLazyLoading={isImgLazyLoading} />;
                })}
                <div className="ab-authors-more">{users.length} authors</div>
            </>
        );
    } else {
        content = users && users[0] && <AuthorsListItem user={users[0]} isImgLazyLoading={isImgLazyLoading} />;
    }
    return <div className="article-box__author">{content}</div>;
}

export function ArticleItem({
    skipWrapper,
    content,
    highlightFeatured,
    wrapperStyles,
    visibleContent,
    visibleContentWithButton,
    isImgLazyLoading,
    hideVideoProgress,
    openLinksInNewTab,
    bookmarkVisible,
    showBrandLink,
}: ContentListItemProps) {
    const currentUser = useContext(CurrentUserContext) as User | undefined;
    // @ts-ignore
    const multipleOwners = content.users && content.users.length;
    // IMPORTANT NOTE: if changing markup of this card, check how it renders in og-content-screenshot/[slug].tsx that used to take social share screenshots
    const descriptionContent = content.text;

    let articleBoxClass = multipleOwners ? 'article-box  article-box--multiple-owners ' : 'article-box type-article';

    const visibleContentClass = descriptionContent && visibleContent ? 'article-box--w-content' : '';

    const visibleContentWithButtonClass =
        descriptionContent && visibleContentWithButton ? 'article-box--w-content-button' : '';

    const articleBoxVisibleContentClass = visibleContentClass || visibleContentWithButtonClass;

    const articleBoxVisCntOnHoverClass =
        descriptionContent && !articleBoxVisibleContentClass ? 'article-box--w-content-hover' : '';

    const isWorkshop = content.category === ContentCategory.Workshop;
    const isFreeWorkshop = isWorkshop && typeof content.access !== 'undefined' && content.access !== AccessType.Premium;
    const isUpcomingWorkshop = isWorkshop && (!content.startDate || content.startDate > new Date());

    const editButtonURL = getEditButtonURL(currentUser, content);

    const elements = (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <article
            className={articleBoxClass + ' ' + articleBoxVisibleContentClass + ' ' + articleBoxVisCntOnHoverClass}
            onMouseOver={() => {
                if (content.access === AccessType.Premium) {
                    event({ action: 'hover-multipass-blocked-item' });
                }
            }}
        >
            {editButtonURL ? <EditButton url={editButtonURL} /> : null}
            <AccessLink content={content} openLinksInNewTab={openLinksInNewTab} />
            <CardHeader content={content} bookmarkVisible={bookmarkVisible} showBrandLink={showBrandLink} />
            <div className="article-box__main">
                <div className="article-box__title" style={{ fontSize: '2.4rem' }}>
                    {content.title}
                </div>
                {content.isTop && (
                    <span className="label label--warning">
                        <FireIcon />
                        Top Content
                    </span>
                )}

                <div className="article-box__body">
                    <div className="type">
                        {(typeof highlightFeatured === 'undefined' || highlightFeatured !== false) && content.featured
                            ? `Featured ${ContentCategory[content.category]}`
                            : ContentCategory[content.category]}
                        {isFreeWorkshop && (
                            <span className="type__label label">{isUpcomingWorkshop ? 'Public' : 'Free'}</span>
                        )}
                    </div>
                    <AuthorsList users={content.users} isImgLazyLoading={isImgLazyLoading} />
                    <CardDescription isWorkshop content={content} />
                </div>
            </div>
            <div className="article-box__bottom">
                <div className="tags-list">
                    {content.tags?.map((tag) => (
                        <TagComponent
                            selectedTag={null}
                            key={tag.slug}
                            tag={tag}
                            openLinksInNewTab={openLinksInNewTab}
                        />
                    ))}
                </div>
            </div>

            {!hideVideoProgress && <VideoProgress content={content} />}
        </article>
    );

    if (!skipWrapper) {
        return (
            <div className="row-margin__item order-1 col-xxl-4 col-md-6" style={wrapperStyles}>
                {elements}
            </div>
        );
    }

    return elements;
}
