import classNames from 'classnames';

export default function JsIcon({ className }: { className?: string }) {
    return (
        <svg className={classNames('icon', 'icon-js', className)} x="0px" y="0px" viewBox="0 0 50 50">
            <path
                fill="none"
                stroke="currentColor"
                strokeWidth={4}
                strokeLinecap="round"
                strokeMiterlimit={10}
                d="M6.667,45C5.75,45,5,44.25,5,43.333V6.667C5,5.75,5.75,5,6.667,5h36.667C44.25,5,45,5.75,45,6.667v36.667C45,44.25,44.25,45,43.333,45H6.667z"
            />
            <path
                fill="currentColor"
                stroke="currentColor"
                strokeWidth={0}
                d="M32,36c0.818,1.335,1.707,2.614,3.589,2.614c1.581,0,2.411-0.79,2.411-1.882c0-1.308-0.858-1.772-2.598-2.533l-0.954-0.409c-2.753-1.173-4.583-2.643-4.583-5.749c0-2.862,2.18-5.04,5.588-5.04c2.426,0,4.17,0.844,5.427,3.055l-2.971,1.908c-0.654-1.173-1.36-1.635-2.456-1.635c-1.118,0-1.826,0.709-1.826,1.635c0,1.145,0.709,1.608,2.346,2.317l0.954,0.409C40.17,32.079,42,33.496,42,36.683C42,40.118,39.301,42,35.677,42c-3.544,0-5.557-1.787-6.677-4L32,36z"
            />
            <path
                fill="currentColor"
                stroke="currentColor"
                strokeWidth={0}
                d="M18.143,36.097C18.75,37.173,19.673,38,21,38c1.269,0,2-0.496,2-2.426V23h4v13.182C27,40.18,24.656,42,21.234,42c-3.092,0-5.324-2.073-6.234-4L18.143,36.097z"
            />
        </svg>
    );
}
