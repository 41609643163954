import { getName, getProfileUrl } from '../../../users/utils';
import { TagComponent } from '../tags-list/TagComponent';
import { ContentListItemProps } from './ContentList';
import { DefaultAvatar } from '../user-profile/DefaultAvatar';
import { AccessLink, CardHeader, VideoProgress } from './common';
import { AccessType, ContentWithEvent } from '@gn/core/type';
import { getAvatar } from 'app/users/utils';
import { event } from 'app/analytics';
import React, { useContext } from 'react';
import { CurrentUserContext } from '../CurrentUserContext';
import { User } from '@sentry/types';
import { EditButton } from './EditButton';
import { getEditButtonURL } from './utils';
import { MentorshipStatus } from '@gn/db/client';
import DOMPurify from 'isomorphic-dompurify';
import FireIcon from '../icons/FireIcon';
import cn from 'classnames';
import Link from 'next/link';
import { handleVideoPage } from 'app/contents/utils';
import { Lang } from '@gn/core/localization';
import { getContentUrl } from '@gn/core/utils/url';

// It should be not less than 80% for 5-6min videos or 70sec because we store video durations in minutes.

export function VideoItem({
    skipWrapper,
    content,
    highlightFeatured,
    wrapperStyles,
    horizontalLayout,
    visibleContent,
    visibleContentWithButton,
    isImgLazyLoading,
    hideVideoProgress,
    openLinksInNewTab,
    bookmarkVisible,
    lang,
    showBrandLink,
}: ContentListItemProps) {
    const currentUser = useContext(CurrentUserContext) as User | undefined;
    const user = content?.users && content.users[0];
    const hasMultipleAuthors = content?.users && content?.users.length > 1;
    const descriptionContent = content.summary || content.text;
    // IMPORTANT NOTE: if changing markup of this card, check how it renders in og-content-screenshot/[slug].tsx that used to take social share screenshots
    let articleBoxClasses =
        horizontalLayout || (content.featured && highlightFeatured)
            ? 'article-box article-box--author-lg'
            : 'article-box article-box--author   ';

    const visibleContentClass = descriptionContent && visibleContent ? 'article-box--w-content' : '';

    const visibleContentWithButtonClass =
        descriptionContent && visibleContentWithButton ? 'article-box--w-content-button' : '';

    const articleBoxVisibleContentClass = visibleContentClass || visibleContentWithButtonClass;

    const articleBoxVisCntOnHoverClass =
        descriptionContent && !articleBoxVisibleContentClass ? 'article-box--w-content-hover' : '';

    const wrapperClasses =
        content.featured && highlightFeatured
            ? 'row-margin__item order-xxl-1 col-xxl-8 order-0'
            : 'row-margin__item order-1 col-xxl-4 col-md-6';

    if (user?.name && countWords(user.name) > 2) {
        articleBoxClasses += ' article-box--name-col3  ';
    }

    const editButtonURL = getEditButtonURL(currentUser, content);

    const hasVideoPage = handleVideoPage((content as unknown) as ContentWithEvent, lang || Lang.EN);

    const elements = (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div
            className={articleBoxClasses + ' ' + articleBoxVisibleContentClass + ' ' + articleBoxVisCntOnHoverClass}
            onMouseOver={() => {
                if (content.access === AccessType.Premium) {
                    event({ action: 'hover-multipass-blocked-item' });
                }
            }}
        >
            {editButtonURL ? <EditButton url={editButtonURL} /> : null}
            <AccessLink content={content} openLinksInNewTab={openLinksInNewTab} />
            <CardHeader content={content} bookmarkVisible={bookmarkVisible} showBrandLink={showBrandLink} />
            <div className="article-box__wrap">
                <div className="article-box__main">
                    <div className="article-box__title">{content.title}</div>
                    {content.isTop && (
                        <span className="label label--warning">
                            <FireIcon />
                            Top Content
                        </span>
                    )}
                </div>
                {hasVideoPage && (
                    <Link style={{ opacity: 0, height: 0 }} href={getContentUrl(content) + '/video'}>
                        Watch video: {content.title}
                    </Link>
                )}
                <div className="article-box__head">
                    <div className="article-box__head-rel">
                        <div className="article-box__body">
                            {user && (
                                <div
                                    className={cn('abh-author', {
                                        'abh-author--multiple': hasMultipleAuthors,
                                    })}
                                >
                                    {content?.users &&
                                        content?.users.map((user) => (
                                            <React.Fragment key={user.id}>
                                                {user.avatar ? (
                                                    <div className="abh-author__img">
                                                        <picture>
                                                            {/*<source*/}
                                                            {/*    srcSet={getAvatar(user, 600)}*/}
                                                            {/*    media="(min-width: 820px)"*/}
                                                            {/*/>*/}
                                                            <img
                                                                src={getAvatar(user)}
                                                                alt={user.name || user.nickname!}
                                                                loading={isImgLazyLoading ? 'lazy' : undefined}
                                                                // @ts-ignore
                                                                fetchpriority={isImgLazyLoading ? undefined : 'high'}
                                                            />
                                                        </picture>
                                                    </div>
                                                ) : (
                                                    <DefaultAvatar className="abh-author__img" user={user} />
                                                )}
                                            </React.Fragment>
                                        ))}

                                    {hasMultipleAuthors ? (
                                        <div className="abh-author__name">{content?.users.length} authors</div>
                                    ) : (
                                        <div className="abh-author__content">
                                            <a href={getProfileUrl(user)} className="abh-author__name-wrap">
                                                <div className="abh-author__info">
                                                    <div
                                                        className="abh-author__name"
                                                        style={smallerFontSize(user.name || user.email)}
                                                    >
                                                        {getName(user)}
                                                        {user.mentorship?.status === MentorshipStatus.Enabled && (
                                                            <div className="ab-author__mentorship">
                                                                Mentorship available
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="abh-author__cat">
                                                        <div>{user.shortBio || user.company}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            )}
                            {descriptionContent && (
                                <div className="article-box__wrap-text">
                                    <div
                                        className="article-box__text"
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(descriptionContent.replace(/&nbsp;/g, ' '), {
                                                ALLOWED_TAGS: ['br'],
                                            }),
                                            // __html: sanitizeHtml(descriptionContent?.replace(/&nbsp;/g, ' '), {
                                            //     allowedTags: [],
                                            //     textFilter: (text: string) => text + '<br />',
                                            // }),
                                        }}
                                    ></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="article-box__bottom">
                <div className="tags-list">
                    {content.tags?.map((tag) => (
                        <TagComponent
                            selectedTag={null}
                            key={tag.slug}
                            tag={tag}
                            openLinksInNewTab={openLinksInNewTab}
                        />
                    ))}
                </div>
            </div>

            {!hideVideoProgress && <VideoProgress content={content} />}
        </div>
    );

    if (!skipWrapper) {
        return (
            <div className={wrapperClasses} style={wrapperStyles}>
                {elements}
            </div>
        );
    }

    return elements;
}

function countWords(str) {
    var matches = str.match(/[\w\d’'-]+/gi);
    return matches ? matches.length : 0;
}

function smallerFontSize(userName: string) {
    const [name, secondName] = userName.split(' ');
    return name.length >= 11 || secondName?.length >= 11 ? { fontSize: '1.8rem' } : {};
}
