import { Routes } from '@blitzjs/next';
import { constructUrl } from '@gn/core/utils/url';
import { useAuthPopup } from 'app/core/hooks/useAuthPopup';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

interface LoginButtonProps {
    mode?: 'simple' | 'popup';
    className?: string;
    children: React.ReactNode | ((onClick?: () => void | Promise<void>) => JSX.Element);
}

const PORTAL_LOGIN = '/login';
const LOGIN_SUCCESS = '/auth/popup/success';

export default function LoginButton({ mode = 'simple', ...others }: LoginButtonProps) {
    return mode === 'simple' ? <SimpleLoginButton {...others} /> : <PopupLoginButton {...others} />;
}

function SimpleLoginButton({ className, children }: LoginButtonProps) {
    const router = useRouter();

    return (
        <Link href={constructUrl(PORTAL_LOGIN, { 'return-to': router.asPath })} className={className} legacyBehavior>
            {typeof children === 'function' ? children() : children}
        </Link>
    );
}

function PopupLoginButton({ children, className }: LoginButtonProps) {
    const router = useRouter();
    const { authenticate } = useAuthPopup({
        authUrl: constructUrl(PORTAL_LOGIN, { 'return-to': LOGIN_SUCCESS }),
    });

    const onClick = async () => {
        try {
            await authenticate();
            router.reload();
        } catch (err) {
            console.error(err);
        }
    };

    return typeof children === 'function' ? (
        children(onClick)
    ) : (
        <button onClick={onClick} className={className}>
            {children}
        </button>
    );
}
