import { Ctx } from 'blitz';
import { ChapterLocalization, Content, ContentChapter, ContentFaq, ContentLocalization, User } from '@gn/db';
import { vimeoAccessToken } from '../../configs';
import { AccessType, ContentCategory, ContentPublicationStatus, ContentWithEvent, MultipassUser } from '@gn/core/type';
import { isMultipassValid } from '@gn/core/multipass/utils';
import fetch from 'node-fetch';
import { UserWithContent } from 'app/types';
import { itemsInRow, ContentConfigItem } from './contentConfig';
import getPublishedContent from '@gn/core/content/queries/getPublishedContent';
import { getPublicationStatus } from '@gn/core/content/utils/client';
import { GetContentsInput } from '@gn/core/content/queries/getContents';
import { formatDate } from '../utils/format';
import { Lang } from '@gn/core/localization';
import { isAfter, subMonths } from 'date-fns';
import { getLastIdFromURL } from '@gn/core/utils/url';
import { OG_IMAGE_SERVICE_URL } from '@gn/core/config';

export function isContentBlockedForUser(
    content: Pick<Content, 'access'>,
    user: MultipassUser | null | undefined,
): boolean {
    const isPremium = content.access === AccessType.Premium;
    return isPremium && (!user || !isMultipassValid(user));
}

export function getContentOgImageUrl(content: { slug: string; updatedAt?: Date | null }): string {
    return `${OG_IMAGE_SERVICE_URL}/${content.slug}?v3${
        content.updatedAt ? `-${new Date(content.updatedAt).getTime()}` : ''
    }`;
}

export function formatContentDate(content: Pick<Content, 'startDate' | 'publishDate'>): string {
    return formatDate(content.startDate || content.publishDate);
}

const PRIORITY_COEF_OF_NEW = 0.8;
const PRIORITY_COEF_OF_POPULAR = 0.8;
const PRIORITY_TWITTER_FOLLOWERS_MIN_AMOUNT = 250;
const PRIORITY_VIEWS_MIN_AMOUNT = 400;

export function orderWithPriority(array: any[]) {
    const newPopular: any[] = [];
    const oldPopular: any[] = [];
    const newUnpopular: any[] = [];
    const oldUnpopular: any[] = [];

    const oneMonthAgo = subMonths(new Date(), 1);
    array.forEach((item) => {
        let random = Math.random();
        const isPopular =
            item.users.some(
                (user) => user.twitterFollowers && user.twitterFollowers > PRIORITY_TWITTER_FOLLOWERS_MIN_AMOUNT,
            ) || item.views > PRIORITY_VIEWS_MIN_AMOUNT;
        const showAsPopular =
            (isPopular && random < PRIORITY_COEF_OF_POPULAR) || (!isPopular && random > PRIORITY_COEF_OF_POPULAR);

        random = Math.random();
        const isNew = isAfter(item.startDate, oneMonthAgo);
        const showAsNew = (isNew && random < PRIORITY_COEF_OF_NEW) || (!isNew && random > PRIORITY_COEF_OF_NEW);

        if (showAsPopular && showAsNew) {
            newPopular.push(item);
        } else if (showAsPopular && !showAsNew) {
            oldPopular.push(item);
        } else if (!showAsPopular && showAsNew) {
            newUnpopular.push(item);
        } else {
            oldUnpopular.push(item);
        }
    });

    return [...newPopular, ...oldPopular, ...newUnpopular, ...oldUnpopular];
}

export const getRequestForContentList = (where, order) => {
    return {
        where,
        skip: undefined,
        take: 25,
        orderBy: [
            ...order,
            {
                publishDate: 'desc',
            },
            {
                views: 'desc',
            },
        ],
    };
};

export function isVimeoURL(url: string) {
    return url.includes('vimeo.com');
}
export function isVimeoPlainURL(url: string) {
    return url.indexOf('vimeo.com') !== -1 && url.indexOf('player.') === -1;
}

export function getPlayerURLFromVimeoURL(url: string) {
    const videoId = getLastIdFromURL(url);
    return 'https://player.vimeo.com/video/' + videoId;
}

export function isYoutubePlainUrl(url: string) {
    return url.indexOf('youtube.com/watch') !== -1;
}

// https://www.youtube.com/watch?v=sKD16YV7PdM&t=600 to https://www.youtube.com/embed/sKD16YV7PdM?start=600
export function getPlayerURLFromYoutubeURL(url: string) {
    const videoId = url.substring(url.lastIndexOf('?v=') + 3, url.length);
    return ('https://www.youtube.com/embed/' + videoId).replace('&t=', '?start=');
}

export function isYoutubeShareUrl(url: string) {
    return url.indexOf('youtu.be') !== -1;
}

// https://youtu.be/iL_KjnHIc0o?t=600 to https://www.youtube.com/embed/iL_KjnHIc0o?start=600
export function getPlayerURLFromYoutubeShareURL(url: string) {
    const videoId = url.substring(url.lastIndexOf('/') + 1, url.length);
    return ('https://www.youtube.com/embed/' + videoId).replace('?t=', '?start=');
}

export async function fetchVideoThumbnail(videoURL: string) {
    if (isVimeoURL(videoURL)) {
        const videoId = getLastIdFromURL(videoURL);
        const url = `https://api.vimeo.com/videos/${videoId}?fields=pictures.sizes.link`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: `bearer ${vimeoAccessToken}`,
                    Accept: 'application/vnd.vimeo.*+json;version=3.4',
                },
                timeout: 1000,
            });
            const pictureData = await response.json();
            // take the biggest last size
            return pictureData.pictures.sizes[pictureData.pictures.sizes.length - 1].link;
        } catch (error) {
            throw error;
        }
    }
}

export function getUniqueSpeakersWithContent(contents: Array<Partial<Content> & { users: Pick<User, 'id'>[] }>) {
    const usersMap: { [id: number]: UserWithContent } = {};
    for (const content of contents) {
        for (const user of content.users) {
            if (!usersMap[user.id]) {
                usersMap[user.id] = {
                    ...user,
                    contents: [
                        {
                            title: content.title,
                        },
                    ],
                };
            } else {
                usersMap[user.id].contents.push({ title: content.title });
            }
        }
    }

    return Object.values(usersMap);
}

export function removeDuplicates(
    contents: Content[][],
): {
    contents: Content[][];
    idsFound: Array<number>;
} {
    const idsFound: Array<number> = [];
    contents.forEach((contentArray, idx) => {
        const toIterate = Math.min(itemsInRow, contentArray.length);
        for (var i = 0; i < toIterate; i++) {
            const content = contentArray[i];
            if (!content) continue;
            if (idsFound.includes(content.id)) {
                contentArray.splice(i, 1);
                i--;
            } else {
                idsFound.push(content.id);
            }
        }
        contents[idx] = contentArray.slice(0, itemsInRow);
    });
    return {
        contents: contents,
        idsFound: idsFound,
    };
}

export async function getContentPromise(request: ContentConfigItem, ctx: Ctx) {
    const { contents } = await getPublishedContent(
        {
            extendWhere: request.where,
            orderBy: request.orderBy,
            categories: request.categories,
            accesses: request.accesses,
            shouldTrimText: true,
            withTwitterFollowers: true,
            extendSelect: {
                views: true,
            },
        },
        ctx,
    );
    return { title: request.title, contents };
}

type ContentToSort = Parameters<typeof getPublicationStatus>[0] & Pick<Content, 'featured'>;
export function createSortContentByStatus(sortingOrder: ContentPublicationStatus[]) {
    return (a: ContentToSort, b: ContentToSort) => {
        const aStatus = getPublicationStatus(a);
        const bStatus = getPublicationStatus(b);
        const aDate = a.startDate?.getTime() || Infinity;
        const bDate = b.startDate?.getTime() || Infinity;

        return sortingOrder.indexOf(aStatus) - sortingOrder.indexOf(bStatus) || aDate - bDate;
    };
}

export const promotedFirst = (a: Pick<Content, 'promotedUntil'>, b: Pick<Content, 'promotedUntil'>) => {
    const aPromoted = a.promotedUntil && a.promotedUntil > new Date() ? a.promotedUntil.getTime() : Infinity;
    const bPromoted = b.promotedUntil && b.promotedUntil > new Date() ? b.promotedUntil.getTime() : Infinity;
    return aPromoted - bPromoted;
};

export const featuredFirst = (a: Pick<Content, 'featured'>, b: Pick<Content, 'featured'>) => {
    return Number(b.featured) - Number(a.featured);
};

export const sortContentByViewsDesc = (a: { views: number }, b: { views: number }) => b.views - a.views;

export const sortPastContent = (
    a: { promotedUntil: Date | null; featured: boolean; views: number },
    b: { promotedUntil: Date | null; featured: boolean; views: number },
) => promotedFirst(a, b) || featuredFirst(a, b) || sortContentByViewsDesc(a, b);

export const getLangFromParams = (params?: { lang?: Lang[] }) => {
    const urlLang: string | undefined =
        typeof params?.lang?.[0] === 'string' ? params?.lang?.[0].toUpperCase() : undefined;
    return Object.values(Lang).some((l) => l === urlLang) ? (urlLang as Lang) : undefined;
};

export const getVideoPlayerUrl = ({
    videoUrl,
    videoURLTimeCode,
}: {
    videoUrl?: string | null;
    videoURLTimeCode?: string;
}) => {
    if (!videoUrl) {
        return '';
    }

    let playerUrl = videoUrl;

    if (isVimeoPlainURL(videoUrl)) {
        playerUrl = getPlayerURLFromVimeoURL(videoUrl) + (videoURLTimeCode || '');
    }
    if (isYoutubePlainUrl(videoUrl)) {
        playerUrl = getPlayerURLFromYoutubeURL(videoUrl);
    }
    if (isYoutubeShareUrl(videoUrl)) {
        playerUrl = getPlayerURLFromYoutubeShareURL(videoUrl);
    }

    return playerUrl;
};

type OptionalDate = Date | null | undefined;
const datesDescending = (a: OptionalDate, b: OptionalDate) =>
    (b ? b.getTime() : -Infinity) - (a ? a.getTime() : -Infinity);
export const getLastUpdatedDates = (content: {
    updatedAt: OptionalDate;
    localizations?: Array<Partial<ContentLocalization>>;
    chapters?: Array<Partial<ContentChapter> & { localizations?: Array<Partial<ChapterLocalization>> }>;
    faqs?: Array<Partial<ContentFaq>>;
}) => {
    const chaptersUpdatedAt = content.chapters?.map((c) => c.updatedAt) ?? [];
    const faqsUpdatedAt = content.faqs?.map((c) => c.updatedAt) ?? [];

    const localizationsDates =
        content.localizations?.map((localization) => ({ lang: localization.lang, values: [localization.updatedAt] })) ??
        [];
    if (content.chapters) {
        for (const chapterLocalizations of content.chapters.map((c) => c.localizations)) {
            if (!chapterLocalizations) continue;

            for (const localization of chapterLocalizations) {
                const existing = localizationsDates.find((l) => l.lang === localization.lang);
                if (existing && localization.updatedAt) {
                    existing.values.push(localization.updatedAt);
                }
            }
        }
    }

    const contentUpdatedAt = [...chaptersUpdatedAt, ...faqsUpdatedAt, content.updatedAt].sort(datesDescending)[0];
    const localizationsUpdatedAt = localizationsDates.map((l) => ({
        lang: l.lang!,
        values: l.values.sort(datesDescending)[0] as OptionalDate,
    }));

    return { contentUpdatedAt, localizationsUpdatedAt } as const;
};

export const handleVideoPage = (content: ContentWithEvent, lang: Lang) => {
    if (
        content.event?.slug?.includes('react') &&
        content.event?.slug?.includes('2023') &&
        lang === Lang.EN &&
        content.category !== ContentCategory.Workshop
    ) {
        return true;
    }
    return false;
};
