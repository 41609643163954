import { ContentCategory, EventTab, EventWithBrand } from '@gn/core/type';
import { Content, Event, Prisma } from '@gn/db/client';
import { SHOW_EVENT_WATCH_BUTTON_DAYS_BEFORE } from 'configs';
import subDays from 'date-fns/subDays';
import DOMPurify from 'isomorphic-dompurify';
import JsIcon from '../core/components/icons/JsIcon';
import ReactIcon from '../core/components/icons/ReactIcon';
import React from 'react';
import Clock2Icon from '../core/components/icons/Clock2Icon';

export function getEventLink(event: Event): string {
    return `/events/${event.slug || event.id}`;
}

type ExtendedEvent = {
    Content: (Pick<Content, 'category'> & { users: unknown[] })[];
    partners: { partnerId: number }[];
};
export function eventWithContentsCount<T extends ExtendedEvent>(event: T) {
    const counts = event.Content?.reduce(
        (result, content) => {
            result[EventTab.SPEAKERS] += content.users?.length ?? 0;

            switch (content.category) {
                case ContentCategory.Workshop:
                    result[EventTab.WORKSHOPS]++;
                    result[EventTab.TALKS]++;
                    break;
                default:
                    result[EventTab.TALKS]++;
                    break;
            }

            return result;
        },
        {
            [EventTab.SPEAKERS]: 0,
            [EventTab.TALKS]: 0,
            [EventTab.WORKSHOPS]: 0,
            [EventTab.PARTNERS]: 0,
        },
    );
    counts[EventTab.PARTNERS] += event.partners?.length ?? 0;

    return { ...event, contents: counts ?? {} };
}

export function getEventWhereClause(idOrSlug: string): Prisma.EventWhereInput {
    const numId = parseInt(idOrSlug);

    return isNaN(numId)
        ? {
              slug: {
                  equals: idOrSlug,
                  mode: 'insensitive',
              },
          }
        : { id: numId };
}

export function isStreamPageAvailable(event: Pick<Event, 'startDate' | 'endDate'>) {
    const now = new Date();
    return subDays(event.startDate, SHOW_EVENT_WATCH_BUTTON_DAYS_BEFORE) < now && now < event.endDate;
}

export function sanitizeEventDescription<T extends string | null | undefined>(text: T) {
    if (typeof text !== 'string') return text;

    return DOMPurify.sanitize(text.replace(/&nbsp;/g, ' '), {
        ALLOWED_TAGS: ['br'],
    }).replaceAll('<br>', '. ');
}

type EventWithBrandName = {
    brand: {
        name: string;
    };
};

const reactBrandsMatcherRegexp = /\b(react|remix)\b/i;
export const isReactEvent = (event: EventWithBrandName) => {
    const name = event.brand.name.toLowerCase();
    return reactBrandsMatcherRegexp.test(name);
};

const jsBrandsMatcherRegexp = /\b(node|amsterdamjs|testjs|jsnation|js|ts)\b/i;
export const isJsEvent = (event: EventWithBrandName) => {
    const name = event.brand.name.toLowerCase();
    return jsBrandsMatcherRegexp.test(name);
};

export const getConfAltTitle = (event: EventWithBrandName, plural = false) => {
    let confAltTitle = '';
    if (isReactEvent(event)) {
        confAltTitle = 'React Conference';
    } else if (isJsEvent(event)) {
        confAltTitle = 'JavaScript Conference';
    } else {
        confAltTitle = 'Tech Conference';
    }
    if (plural) {
        confAltTitle += 's';
    }
    return confAltTitle;
};

export const getConfListLink = (event: EventWithBrandName) => {
    let confListSlug = '';
    if (isReactEvent(event)) {
        confListSlug = '/react-conferences';
    } else if (isJsEvent(event)) {
        confListSlug = '/javascript-conferences';
    }
    return `/events${confListSlug}`;
};

export const getConfListIcon = (event: EventWithBrandName) => {
    let icon = <Clock2Icon />;
    if (isReactEvent(event)) {
        icon = <ReactIcon />;
    } else if (isJsEvent(event)) {
        icon = <JsIcon />;
    }
    return icon;
};
