import { ADDEVENT_CLIENT_ID } from '../config/server';

export interface CalendarEvent {
    title: string;
    description?: string | null;
    startDate: Date;
    endDate?: Date | null;
    location?: string | null;
}

const ADDEVENT_URL = 'https://www.addevent.com/dir';

export function generateCalendarUrl({ title, description, startDate, endDate, location }: CalendarEvent) {
    const url = new URL(ADDEVENT_URL);
    const params = url.searchParams;

    params.append('client', ADDEVENT_CLIENT_ID);
    params.append('title', title);
    params.append('start', startDate.toISOString());
    params.append('timezone', 'UTC');
    endDate && params.append('end', endDate.toISOString());
    location && params.append('location', location);
    description && params.append('description', description);

    return url.href;
}
